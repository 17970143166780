import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { ChevronDown, Menu, X } from "lucide-react";
import "./MainMenu.scss";
import i18n from "i18n";

const withLangPrefix = (link, lang) => {
  return lang === "hu"
    ? link
    : `/${lang}${link.startsWith("/") ? link : "/" + link}`;
};

const MegaMenu = ({ item, language }) => {
  const [open, setOpen] = useState(false);
  return (
    <div
      className="relative megamenu-container"
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
    >
      <button className="p-2 text-lg hover:bg-gray-200 rounded">
        {i18n.t(item.title)} <ChevronDown size={16} />
      </button>
      {item.children && (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: open ? 1 : 0, y: open ? 0 : 0 }}
          exit={{ opacity: 0, y: 0 }}
          className={`absolute left-0 w-auto bg-white shadow-lg p-4 flex space-x-8 megamenu ${
            open ? "active" : ""
          } hidden md:flex`}
        >
          {item.children.map((column, idx) => (
            <div key={idx} className="w-48 megamenu-column">
              <h3 className="font-semibold border-b pb-2 mb-2">
                {i18n.t(column.title)}
              </h3>
              {column.children &&
                column.children.map((subitem, subIdx) => (
                  <Link
                    key={subIdx}
                    to={withLangPrefix(subitem.link, language)}
                    className="block text-gray-700 hover:text-black p-1"
                  >
                    {i18n.t(subitem.title)}
                  </Link>
                ))}
            </div>
          ))}
        </motion.div>
      )}
    </div>
  );
};

const MainMenu = () => {
  const [menuData, setMenuData] = useState([]);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [language, setLanguage] = useState(i18n.language);
  const location = useLocation();

  const loadMenuData = async (lang) => {
    try {
      const data = await import(
        `../../../resources/locales/${lang}/menudata.json`
      );
      setMenuData(data.default);
    } catch (error) {
      console.error("❌ Error loading menu data:", error);
    }
  };

  useEffect(() => {
    loadMenuData(i18n.language);

    const handleLanguageChange = (lng) => {
      setLanguage(lng);
      loadMenuData(lng);
    };

    i18n.on("languageChanged", handleLanguageChange);

    return () => {
      i18n.off("languageChanged", handleLanguageChange);
    };
  }, []);

  useEffect(() => {
    setMobileOpen(false);
  }, [location]);

  return (
    <nav className="p-4 bg-[#B89C67] text-white navbar">
      {/* Desktop Menu */}
      <div className="desktop-menu">
        {menuData.map((item, index) =>
          item.children ? (
            <MegaMenu key={index} item={item} language={language} />
          ) : (
            <Link
              key={index}
              to={withLangPrefix(item.link, language)}
              className="p-2 hover:underline"
            >
              {i18n.t(item.title)}
            </Link>
          )
        )}
      </div>

      {/* Mobile Menu Toggle */}
      <div className="mobile-menu">
        <button
          onClick={() => setMobileOpen(!mobileOpen)}
          className="text-white"
        >
          {mobileOpen ? <X size={28} /> : <Menu size={28} />}
        </button>
      </div>

      {/* Mobile Menu */}
      {mobileOpen && (
        <motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: "auto" }}
          exit={{ opacity: 0, height: 0 }}
          className="md:hidden flex flex-col bg-[#B89C67] p-4 absolute top-16 left-0 w-full shadow-lg"
        >
          {menuData.map((item, index) => (
            <div key={index} className="mb-2">
              {item.children ? (
                <>
                  <button
                    onClick={() =>
                      setMenuData((prev) =>
                        prev.map((menuItem, i) =>
                          i === index
                            ? { ...menuItem, open: !menuItem.open }
                            : menuItem
                        )
                      )
                    }
                    className="text-lg w-full text-left p-2"
                  >
                    {i18n.t(item.title)} <ChevronDown size={16} />
                  </button>
                  {item.open && (
                    <div className="pl-4">
                      {item.children.map((subitem, subIdx) => (
                        <div className="submenu-item" key={subIdx}>
                          <h3> {i18n.t(subitem.title)}</h3>

                          {subitem.children && (
                            <div className="pl-4">
                              {subitem.children.map((subSubItem, subSubIdx) => (
                                <Link
                                  key={subSubIdx}
                                  to={withLangPrefix(subSubItem.link, language)}
                                  className="block"
                                  onClick={() => setMobileOpen(false)}
                                >
                                  {i18n.t(subSubItem.title)}
                                </Link>
                              ))}
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                </>
              ) : (
                <Link
                  to={withLangPrefix(item.link, language)}
                  className="block p-2 hover:bg-gray-700"
                  onClick={() => setMobileOpen(false)}
                >
                  {i18n.t(item.title)}
                </Link>
              )}
            </div>
          ))}
        </motion.div>
      )}
    </nav>
  );
};

export default MainMenu;
