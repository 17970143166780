import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useParams } from "react-router";
import { Helmet } from "react-helmet-async";
import PostContent from "../organisms/PostContent";
import { useTranslation } from "react-i18next";
import "./SimplePage.scss";

export default function SimplePage() {
  const { lang, pageSlug } = useParams();
  const activeLang = lang || "hu";
  const langPrefix = activeLang === "hu" ? "" : `/${activeLang}`;
  const { i18n } = useTranslation();
  const [page, setPage] = useState(null);
  const [featuredImage, setFeaturedImage] = useState(null);

  useEffect(() => {
    const fetchPage = async () => {
      try {
        const res = await fetch(
          `https://backend.hnh.hu${langPrefix}/wp-json/wp/v2/pages?slug=${pageSlug}&_fields=id,content,title,yoast_head_json,featured_media`
        );
        const data = await res.json();
        if (Array.isArray(data) && data.length > 0) {
          setPage(data[0]);
        }
      } catch (error) {
        console.error("❌ Hiba az oldal lekérésekor:", error);
      }
    };

    fetchPage();
  }, [langPrefix, pageSlug]);

  useEffect(() => {
    if (page?.featured_media) {
      fetch(`https://backend.hnh.hu/wp-json/wp/v2/media/${page.featured_media}`)
        .then((res) => res.json())
        .then((media) => {
          if (media.source_url) {
            setFeaturedImage(media.source_url);
          }
        })
        .catch((err) => console.error("Hiba a kiemelt kép lekérésekor:", err));
    }
  }, [page?.featured_media]);

  if (!page) {
    return <Container>Loading...</Container>;
  }

  const seoData = page?.yoast_head_json || {};

  return (
    <>
      <Helmet>
        <html lang={i18n.language} />
        <link
          rel="canonical"
          href={`https://www.hnh.hu${window.location.pathname}`}
        />
        <title>
          {seoData?.title ||
            page?.title?.rendered ||
            "Fogászati Kezelések | HNH Fogászat"}
        </title>
        <meta
          name="description"
          content={
            seoData?.description ||
            "Professzionális fogászati kezelések Székesfehérváron. Tudjon meg többet korszerű megoldásainkról!"
          }
        />
        <meta
          name="keywords"
          content={
            Array.isArray(seoData?.schema?.keywords)
              ? seoData.schema.keywords.join(", ")
              : "fogászat, fogorvos, fogászati kezelések, Székesfehérvár"
          }
        />
        <meta
          property="og:title"
          content={seoData?.og_title || page?.title?.rendered}
        />
        <meta
          property="og:description"
          content={seoData?.og_description || seoData?.description}
        />
        <meta
          property="og:image"
          content={seoData?.og_image?.[0]?.url || featuredImage}
        />
        <meta
          property="og:url"
          content={seoData?.canonical || window.location.href}
        />
        <meta name="robots" content="index, follow" />
        <link
          rel="canonical"
          href={seoData?.canonical || window.location.href}
        />
      </Helmet>

      <Container>
        {featuredImage && (
          <div
            className="featured-image"
            style={{
              backgroundImage: featuredImage ? `url(${featuredImage})` : "none",
            }}
          ></div>
        )}
        <PostContent html={page?.content?.rendered} />
      </Container>
    </>
  );
}
