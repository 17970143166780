import { createBrowserRouter } from "react-router-dom";

// Layouts
import LandingPageLayout from "client/components/layouts/LandingPageLayout";
import BlogLayout from "client/components/layouts/BlogLayout";
import SimplePageLayout from "client/components/layouts/SimplePageLayout";

// Pages
import HomePage from "client/components/pages/HomePage";
import ErrorPage from "client/components/pages/ErrorPage";
import ContactsPage from "client/components/pages/ContactsPage";
import ContactPage from "client/components/pages/ContactPage";
import RecommendationsPage from "client/components/pages/RecommendationsPage";
import PostsPage from "client/components/pages/blog/PostsPage";
import PostPage from "client/components/pages/blog/PostPage";
import ScraperPage from "client/components/pages/ScraperPage";
import SimplePage from "client/components/pages/SimplePage";
import QAPage from "client/components/pages/QAPage";

const router = createBrowserRouter([
  // Magyar nyelv - prefix nélkül
  {
    path: "/",
    element: <LandingPageLayout />,
    errorElement: <ErrorPage />,
    children: [
      { path: "", element: <HomePage /> },
      { path: "kapcsolat", element: <ContactPage /> },
      { path: "contact", element: <ContactPage /> },
      { path: "kontakt", element: <ContactPage /> },
      { path: "scraper", element: <ScraperPage /> },
      { path: "contacts/:contactId", element: <ContactsPage /> },
      { path: "ertekelesek", element: <RecommendationsPage /> },
      { path: "fogaszat/:pageSlug", element: <SimplePage /> },
      { path: "kezelesek/:pageSlug", element: <SimplePage /> },
      { path: "zahnklinik/:pageSlug", element: <SimplePage /> },
      { path: "behandlungen/:pageSlug", element: <SimplePage /> },
      { path: "dentistry/:pageSlug", element: <SimplePage /> },
      { path: "treatments/:pageSlug", element: <SimplePage /> },
      { path: "a-fogorvos-valaszol", element: <QAPage /> },
    ],
  },
  {
    path: "/blog",
    element: <BlogLayout />,
    children: [
      { path: "", element: <PostsPage /> },
      { path: ":postId", element: <PostPage /> },
    ],
  },
  // Idegen nyelvek prefix-szel
  {
    path: "/:lang",
    element: <LandingPageLayout />,
    errorElement: <ErrorPage />,
    children: [
      { path: "", element: <HomePage /> },
      { path: "kapcsolat", element: <ContactPage /> },
      { path: "contact", element: <ContactPage /> },
      { path: "kontakt", element: <ContactPage /> },
      { path: "scraper", element: <ScraperPage /> },
      { path: "contacts/:contactId", element: <ContactsPage /> },
      { path: "ertekelesek", element: <RecommendationsPage /> },
      { path: "fogaszat/:pageSlug", element: <SimplePage /> },
      { path: "kezelesek/:pageSlug", element: <SimplePage /> },
      { path: "a-fogorvos-valaszol", element: <QAPage /> },
      { path: "zahnklinik/:pageSlug", element: <SimplePage /> },
      { path: "behandlungen/:pageSlug", element: <SimplePage /> },
      { path: "dentistry/:pageSlug", element: <SimplePage /> },
      { path: "treatments/:pageSlug", element: <SimplePage /> },
    ],
  },
  {
    path: "/:lang/blog",
    element: <BlogLayout />,
    children: [
      { path: "", element: <PostsPage /> },
      { path: ":postId", element: <PostPage /> },
    ],
  },
]);

export default router;
