import { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import "./PostPage.scss";

export default function PostPage() {
  const { lang, postId } = useParams();
  const activeLang = lang || "hu";
  const { i18n } = useTranslation();
  const [post, setPost] = useState(null);

  useEffect(() => {
    const fetchPost = async () => {
      const langPrefix = activeLang === "hu" ? "" : `/${activeLang}`;
      const apiUrl = `https://backend.hnh.hu${langPrefix}/wp-json/wp/v2/posts?slug=${postId}&_fields=id,content,title,yoast_head_json`;

      try {
        const res = await fetch(apiUrl);
        const data = await res.json();
        setPost(data[0]);
      } catch (error) {
        console.error("❌ Hiba a blogposzt lekérésekor:", error);
      }
    };

    fetchPost();
  }, [activeLang, postId]);

  const seoData = post?.yoast_head_json;

  if (!post) return <div>Betöltés...</div>;

  return (
    <>
      <Helmet>
        <html lang={i18n.language} />
        <link
          rel="canonical"
          href={`https://www.hnh.hu${window.location.pathname}`}
        />
        <title>{seoData?.title || post?.title?.rendered}</title>
        <meta name="description" content={seoData?.description} />
        <meta name="keywords" content={seoData?.schema?.keywords?.join(", ")} />
        <meta property="og:title" content={seoData?.og_title} />
        <meta property="og:description" content={seoData?.og_description} />
        <meta property="og:image" content={seoData?.og_image?.[0]?.url} />
        <meta property="og:url" content={seoData?.canonical} />
      </Helmet>
      <div className="post-page">
        <Container>
          <h1>{post?.title?.rendered}</h1>
          <div dangerouslySetInnerHTML={{ __html: post?.content?.rendered }} />
        </Container>
      </div>
    </>
  );
}
