import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Pagination from "react-bootstrap/Pagination";
import BlogSidebar from "./BlogSidebar";
import "./PostsPage.scss";

export default function PostsPage() {
  const { lang } = useParams();
  const activeLang = lang || "hu";

  const postsPerPage = 12;
  const [currentPage, setCurrentPage] = useState(1);
  const [posts, setPosts] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedTag, setSelectedTag] = useState(null);

  useEffect(() => {
    const fetchPosts = async () => {
      let apiUrl = `https://backend.hnh.hu${
        activeLang === "hu" ? "" : `/${activeLang}`
      }/wp-json/wp/v2/posts?per_page=${postsPerPage}&page=${currentPage}&_fields=id,excerpt,title,slug`;

      if (selectedCategory) {
        apiUrl += `&categories=${selectedCategory}`;
      }
      if (selectedTag) {
        apiUrl += `&tags=${selectedTag}`;
      }

      const response = await fetch(apiUrl);
      const data = await response.json();
      setPosts(data);
      setPageCount(
        Math.ceil(response.headers.get("X-WP-Total") / postsPerPage)
      );
    };

    fetchPosts();
  }, [currentPage, selectedCategory, selectedTag, activeLang]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="posts-page">
      <Container>
        <div className="blog-content">
          <div className="blog-main">
            <Row xs={1} md={2} lg={3} className="g-4">
              {posts.map((post, index) => (
                <Col key={index}>
                  <Card data-testid={`post-${index}`}>
                    <Card.Body>
                      <Link
                        to={`/${
                          activeLang !== "hu" ? `${activeLang}/` : ""
                        }blog/${post.slug}`}
                      >
                        <Card.Title
                          dangerouslySetInnerHTML={{
                            __html: post.title.rendered,
                          }}
                        />
                      </Link>
                      <Card.Text
                        dangerouslySetInnerHTML={{
                          __html: post.excerpt.rendered,
                        }}
                      />
                      <Card.Link
                        to={`/${
                          activeLang !== "hu" ? `${activeLang}/` : ""
                        }blog/${post.slug}`}
                      >
                        Tovább olvasom
                      </Card.Link>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
          <div className="pagination-container">
            <Pagination>
              {Array.from({ length: pageCount }, (_, i) => (
                <Pagination.Item
                  key={i + 1}
                  active={i + 1 === currentPage}
                  onClick={() => handlePageChange(i + 1)}
                >
                  {i + 1}
                </Pagination.Item>
              ))}
            </Pagination>
          </div>
        </div>
      </Container>
      <BlogSidebar
        setSelectedCategory={setSelectedCategory}
        setSelectedTag={setSelectedTag}
      />
    </div>
  );
}
