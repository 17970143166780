import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ReactGoogleReviews } from "react-google-reviews";
import "react-google-reviews/dist/index.css";
import "./Testimonials.scss";

export default function Testimonials() {
  const { t } = useTranslation();
  const featurableWidgetId = "a742c543-a8a0-4e87-9403-eaf1cc39a18f";

  useEffect(() => {
    const observer = new MutationObserver(() => {
      const images = document.querySelectorAll(".testimonials img:not([alt])");

      images.forEach((img) => {
        img.setAttribute("alt", "Google user avatar");

        if (!img.hasAttribute("width")) {
          img.setAttribute("width", "40");
        }
        if (!img.hasAttribute("height")) {
          img.setAttribute("height", "40");
        }
      });
    });

    const interval = setInterval(() => {
      const container = document.querySelector(".testimonials");
      if (container) {
        observer.observe(container, { childList: true, subtree: true });
        clearInterval(interval);
      }
    }, 200);

    return () => {
      clearInterval(interval);
      observer.disconnect();
    };
  }, []);

  return (
    <section className="content testimonials">
      <h2>{t("testimonials.headline")}</h2>
      <ReactGoogleReviews layout="carousel" featurableId={featurableWidgetId} />
      <a
        className="review-button"
        href="https://www.google.com/search?hl=hu-HU&gl=hu&q=Sz%C3%A9kesfeh%C3%A9rv%C3%A1r,+Dr.+Horv%C3%A1th+-+H%26H+Dental+Fogorvosi+Rendel%C5%91,+Budai+%C3%BAt+20,+8000&ludocid=13672429174120702337&lsig=AB86z5XZsCYXmfJeqngCHodn6-sj#lrd=0x4769f7a56e533713:0xbdbe39e4ec26e181,3"
        target="_blank"
        rel="noopener noreferrer"
      >
        {t("testimonials.add")}
      </a>
    </section>
  );
}
